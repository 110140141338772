// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faAngleDown       as fasFaAngleDown       } from "@fortawesome/pro-solid-svg-icons/faAngleDown";
import { faAngleLeft       as fasFaAngleLeft       } from "@fortawesome/pro-solid-svg-icons/faAngleLeft";
import { faAngleRight      as fasFaAngleRight      } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { faBars            as fasFaBars            } from "@fortawesome/pro-solid-svg-icons/faBars";
import { faBoxArchive      as fasFaBoxArchive      } from "@fortawesome/pro-solid-svg-icons/faBoxArchive";
import { faCircleQuestion  as fasFaCircleQuestion  } from "@fortawesome/pro-solid-svg-icons/faCircleQuestion";
import { faCircleUser      as fasFaCircleUser      } from "@fortawesome/pro-solid-svg-icons/faCircleUser";
import { faCircleXmark     as fasFaCircleXmark     } from "@fortawesome/pro-solid-svg-icons/faCircleXmark";
import { faClock           as fasFaClock           } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faComment         as fasFaComment         } from "@fortawesome/pro-solid-svg-icons/faComment";
import { faFile            as fasFaFile            } from "@fortawesome/pro-solid-svg-icons/faFile";
import { faFileAudio       as fasFaFileAudio       } from "@fortawesome/pro-solid-svg-icons/faFileAudio";
import { faFileCode        as fasFaFileCode        } from "@fortawesome/pro-solid-svg-icons/faFileCode";
import { faFileExcel       as fasFaFileExcel       } from "@fortawesome/pro-solid-svg-icons/faFileExcel";
import { faFileImage       as fasFaFileImage       } from "@fortawesome/pro-solid-svg-icons/faFileImage";
import { faFileLines       as fasFaFileLines       } from "@fortawesome/pro-solid-svg-icons/faFileLines";
import { faFilePdf         as fasFaFilePdf         } from "@fortawesome/pro-solid-svg-icons/faFilePdf";
import { faFilePowerpoint  as fasFaFilePowerpoint  } from "@fortawesome/pro-solid-svg-icons/faFilePowerpoint";
import { faFileVideo       as fasFaFileVideo       } from "@fortawesome/pro-solid-svg-icons/faFileVideo";
import { faFileWord        as fasFaFileWord        } from "@fortawesome/pro-solid-svg-icons/faFileWord";
import { faFileZipper      as fasFaFileZipper      } from "@fortawesome/pro-solid-svg-icons/faFileZipper";
import { faFolder          as fasFaFolder          } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { faMagnifyingGlass as fasFaMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons/faMagnifyingGlass";
import { faSort            as fasFaSort            } from "@fortawesome/pro-solid-svg-icons/faSort";
import { faTag             as fasFaTag             } from "@fortawesome/pro-solid-svg-icons/faTag";

/**
 * Add solid icons
 */
library.add(fasFaAngleDown, fasFaAngleLeft, fasFaAngleRight, fasFaBars, fasFaBoxArchive, fasFaCircleQuestion, fasFaCircleUser, fasFaCircleXmark, fasFaClock, fasFaComment, fasFaFile, fasFaFileAudio, fasFaFileCode, fasFaFileExcel, fasFaFileImage, fasFaFileLines, fasFaFilePdf, fasFaFilePowerpoint, fasFaFileVideo, fasFaFileWord, fasFaFileZipper, fasFaFolder, fasFaMagnifyingGlass, fasFaSort, fasFaTag);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
